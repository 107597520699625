import _ from 'underscore'
import Compressor from 'compressorjs'
import moment from 'moment'

export const OCRhelper = async file => {
  let Result: any = null
  setwatermark(file)
  await new Promise(async (resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      success(result) {
        let reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onload = async () => {
          Result = await submitToGoogle(
            (reader.result as any)?.split('base64,')[1]
          )
          resolve(Result)
        }
        reader.onerror = error => {
          console.log(error)
        }
      },
      error(err) {
        console.log(err.message)
        reject(err)
      },
    })
  })
  return { ...Result, picture: image, Blob: blob }
}

var image = null
var blob = null

const setwatermark = file => {
  // console.log("🚀 ~ file: OCRhelper.ts ~ line 36 ~ file", file)
  new Compressor(file, {
    strict: false,
    drew(context, canvas) {
      context.fillStyle = '#000'
      context.font = '8vh serif'
      context.textAlign = 'center'
      context.fillText('HotelX use only', canvas.width / 2, canvas.height / 2)
    },
    success: function (result) {
      var newImage = new Image()
      newImage.src = URL.createObjectURL(result)
      newImage.alt = 'Compressed image'
      let reader = new FileReader()
      reader.readAsDataURL(result)
      reader.onload = () => {
        blob = result
        // console.log("🚀 ~ file: OCRhelper.ts ~ line 54 ~ reader", reader)
        image = reader.result
        //save file in database
      }
      reader.onerror = error => {
        console.log(error)
      }
    },
    error: function (err) {
      window.alert(err.message)
    },
  })
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

// const key = 'AIzaSyDDQmpMrtq4dMjp4GBp1yLUN3IDCVnkPAY'
const submitToGoogle = async uri => {
  try {
    const body = JSON.stringify({
      requests: [
        {
          features: [
            {
              type: 'DOCUMENT_TEXT_DETECTION',
              maxResults: 50,
            },
            {
              type: 'TEXT_DETECTION',
              maxResults: 50,
            },
          ],
          image: {
            content: uri,
          },
        },
      ],
    })

    const response = await fetch(
      `https://vision.googleapis.com/v1/images:annotate?key=${
        process.env.REACT_APP_API_URL === 'prod-build'
          ? process.env.REACT_APP_OCR_PRO_KEY
          : process.env.REACT_APP_OCR_KEY
      }`,
      {
        headers: {
          Accept: 'application/json',
        },
        method: 'POST',
        body: body,
      }
    )
    const responseJson = await response.json()
    return await OCRProcessing('Google', responseJson)
  } catch (error) {
    console.log(error)
  }
}

const getPassportCountry = async countryCode => {
  let endpoint = 'https://restcountries.eu/rest/v2/alpha/'
  let response = await fetch(`${endpoint}${countryCode}`, {
    method: 'GET',
  })
  let result = await response.json()
  // let countryInfo: any = {
  //   country: result.name,
  //   nationality: result.demonym,
  // };
  let country = result.name
  let nationality = result.demonym
  return { country, nationality }
}

export const OCRProcessing = async (mode, tempResponse) => {
  //! start mykad
  var scanData =
    mode === 'Huawei'
      ? (tempResponse as any)?.split('\n')
      : (tempResponse as any)?.responses[0]?.fullTextAnnotation?.text.split(
          '\n'
        )

  // var scanData = (tempResponse as any)?.responses[0]?.fullTextAnnotation?.text.split(
  //   '\n'
  // )

  let scanDataArr = []
  //Todo  remove 1 char; special case
  for (var i = 0; i < scanData?.length; i++) {
    if (scanData[i].length >= 5) {
      //! only select those data > 4 char
      scanDataArr.push(scanData[i])
    }
  }
  //Todo  remove unwanted data ; special case
  scanDataArr = _.without(scanDataArr, 'MyKad') //! remove mykad picture  if exist
  scanDataArr = _.without(scanDataArr, 'Mykad') //! remove mykad picture  if exist
  scanDataArr = _.without(scanDataArr, 'MALAYSIA') //! remove mykad picture  if exist
  scanDataArr = _.without(scanDataArr, 'IDENTITY CARD') //! remove mykad picture  if exist
  scanDataArr = _.without(scanDataArr, '') //! remove empty string

  let regex_myr_mykad = /mykad/gim
  let regex_myr_mytentera = /Tentera/gim
  let regex_myr_identity = /\d{6}-\d{2}-\d{4}/gim
  let regex_passport = /passport|<{1,}/gim

  let isMyKad =
    scanData.findIndex(str => regex_myr_mykad.test(str)) > 0 ||
    scanData.findIndex(str => regex_myr_identity.test(str)) > 0
  let isPassport = scanData.findIndex(str => regex_passport.test(str)) > 0
  let isMilitaryCard =
    scanData.findIndex(str => regex_myr_mytentera.test(str)) > 0
  let mykadno = ''
  let mykadname = ''
  let mykadnameline = 99
  let mykadaddress = ''
  let mykadpostcodeline = 99
  let mykadpostcode = ''
  let mykadcity = ''
  let mykadstate = ''
  let passportdata1 = ''
  let passportdata2 = ''
  let passportcountrycode = ''
  let passportnationality = ''
  let passportcountry = ''
  let passportholdername = ''
  let passportnumber = ''
  let passportbirthdate = ''
  let passportgender = ''
  let passportexpiredate = ''
  let passportidentityno = ''

  if (isMyKad) {
    // * extract identity card number
    let index_identity = scanData.findIndex(str => regex_myr_identity.test(str))
    mykadno = scanData[index_identity]

    // * extract date of birth
    // let dateOfBirth = moment(mykadno.substr(0, 6), 'YYMMDD').toISOString()

    // * extract address
    let regex_address_postcode = /((?!\d).|^)\d{5}(?!\d)/i
    let index_postcode = scanData.findIndex(str =>
      regex_address_postcode.test(str)
    )

    let index_start_address = index_postcode - 1
    /**
     * ? check previous line (max 2 line) of string is it part of address
     * ? by identifying is there any digit exist (House / unit no.)
     */
    // * Check 2 line before
    if (/\d+/.test(scanData[index_start_address - 2])) {
      index_start_address -= 2
    }
    // * Check 1 line before
    if (/\d+/.test(scanData[index_start_address - 1])) {
      index_start_address -= 1
    }

    if (isMilitaryCard) {
      index_start_address -= 2
    }

    mykadaddress = scanData.slice(index_start_address, index_postcode).join(' ')
    mykadpostcode = scanData[index_postcode].substr(0, 5)
    mykadcity = scanData[index_postcode].substr(6)
    mykadstate = scanData[index_postcode + 1].trim()

    // * extract name
    mykadname = isMilitaryCard
      ? scanData
          .slice(index_identity + 2, index_start_address)
          // .filter(name => name.includes(' '))
          .join(' ')
      : scanData
          .slice(index_identity + 1, index_start_address)
          // .filter(name => name.includes(' '))
          .join(' ')
  } else {
    // * extract passport footer info
    let footerInfo = scanData
      .filter(str => str.includes('<'))
      .map(str => str.replace(/\s/g, ''))

    if (footerInfo.length !== 2) throw new Error('Unable to analyse')

    /**
       Extract info from passport
       Refer wikipedia for machine readable passport format.
       https://en.wikipedia.org/wiki/Machine-readable_passport
     */
    passportdata1 = footerInfo[0]
    passportdata2 = footerInfo[1]
    passportcountrycode = footerInfo[0].substr(2, 3)

    let nameInfo = footerInfo[0].substr(5, 39)
    let surname = nameInfo.substring(0, nameInfo.indexOf('<'))
    let givenName = nameInfo.substring(nameInfo.indexOf('<')).replace(/</g, ' ')

    passportholdername = surname + givenName
    passportholdername = passportholdername.trim()
    passportnumber = footerInfo[1].substr(0, 9)
    passportbirthdate = moment(
      footerInfo[1].substr(13, 6),
      'YYMMDD'
    ).toISOString() // >> format in "YYMMDD"
    passportgender = footerInfo[1].substr(20, 1)
    passportexpiredate = moment(
      footerInfo[1].substr(21, 6),
      'YYMMDD'
    ).toISOString()

    let countryInfo = await getPassportCountry(passportcountrycode)

    passportnationality = countryInfo?.nationality
    passportcountry = countryInfo.country
    passportexpiredate = ''
  }

  return {
    isMyKad,
    isPassport,
    mykadno,
    mykadname,
    mykadnameline,
    mykadaddress,
    mykadpostcodeline,
    mykadpostcode,
    mykadcity,
    mykadstate,
    passportdata1,
    passportdata2,
    passportcountrycode,
    passportnationality,
    passportcountry,
    passportholdername,
    passportnumber,
    passportbirthdate,
    passportgender,
    passportexpiredate,
    passportidentityno,
  }
}
