import React from 'react'
import { CommonDialog } from '@ifca-ui/neumorphism'
import { TextField, Button, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { amtNumStr, amtStr, isBigNumber } from 'containers/Utils/numFormatter'
import { Nationality } from 'generated/graphql'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import BigNumber from 'bignumber.js'

export const TaxesFeeDialog = ({
  state,
  dispatch,
  ServiceCharge,
  ServiceChargeAmount,
  Tax,
  TaxAmount,
  TotalTaxesFeeAmount,
}: any) => {
  return (
    <CommonDialog
      open={state.TaxesDialog}
      onClose={() => {
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
      }}
      sections={{
        content: (
          <>
            <div className="m-b-12 title color-blue-2">Taxes & Fees</div>
            <div className="flex-width color-text-1 m-b-10 ">
              <span className="xsTitle flex-space">
                Service Charge ({ServiceCharge}%)
              </span>
              <span className="xsTitle icon-text">
                {' '}
                {amtStr(ServiceChargeAmount)}
              </span>
            </div>
            <div className="flex-width color-text-1 m-b-10">
              <span className="xsTitle flex-space">Tax ({Tax}%)</span>
              <span className="xsTitle icon-text"> {amtStr(TaxAmount)}</span>
            </div>
            <div className="b-b m-b-10" />
            <div className="flex-width  ">
              <span className="fs-14 fw-bold flex-space">Total</span>
              <span className="fs-14 fw-bold title icon-text">
                <MonetizationOnIcon /> {amtStr(TotalTaxesFeeAmount)}
              </span>
            </div>
          </>
        ),
        footer: {
          children: (
            <>
              {/* <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() =>
                  dispatch({
                    type: 'CloseDialog',
                    payload: {},
                  })
                }
              >
                Cancel
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() =>
                  dispatch({
                    type: 'CloseTaxesDialog',
                    payload: {},
                  })
                }
              >
                Close
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
