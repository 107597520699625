import AccountRoutes from 'containers/Module/Account/Routes'
import { PUBLIC_PATH } from 'containers/Module/Auth/Routes'
import BookingRoutes from 'containers/Module/Booking/Routes'
import ServicesRoutes from 'containers/Module/Services/Routes'
import React from 'react'
import { Redirect } from 'react-router'

//! Redirect to Home page
const Root = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

//! Redirect to 404 page
const Error = [
  {
    component: <Redirect to={PUBLIC_PATH.NOT_FOUND_404} />,
  },
]
// Error component must at the end of array.
const RootRoutes = [
  ...BookingRoutes,
  ...ServicesRoutes,
  ...AccountRoutes,
  ...Root,
  ...Error,
]

export default RootRoutes
