import {
  CommonDialog,
  ContentWrapper,
  Spinner,
  StyledMenu,
} from '@ifca-ui/neumorphism'
import {
  Avatar,
  Button,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MoreIcon from 'assets/svg/more.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import dateFormat from 'dateformat'
import {
  useFrontDeskServiceRequestListingQuery,
  useHousekeepingServiceRequestUpdateMutation,
  ServiceRequestStatus,
} from 'generated/graphql'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { SERVICE_PATH } from '../Routes'
import CompletedIcon from 'assets/svg/completed.svg'
import { useMenuOption } from 'containers/hooks/useMenuOption'
import { FormatListBulletedSharp } from '@material-ui/icons'
import CancelIcon from 'assets/svg/cancel.svg'

export const ServiceRequest: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const { menu, anchorEl, handleClose, handleClick } = useMenuOption()

  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  console.log(localState, 'list')
  const {
    loading,
    refetch,
    data: { FrontDeskServiceRequestListing } = {
      FrontDeskServiceRequestListing: [],
    },
  } = useFrontDeskServiceRequestListingQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
      RegistrationID: localState?.Registration?.ID,
      RoomID: localState?.Room?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Service Request',
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [CancelDialog, setCancelDialog] = useState<boolean>(false)
  const [
    HousekeepingServiceRequestUpdate,
    { loading: UpdateLoading },
  ] = useHousekeepingServiceRequestUpdateMutation()
  const CancelRequest = () => {
    let ServiceRequestInput = {
      ID: menu.data?.ID,
      AccountID: localState?.Hotel?.AccountID,
      HotelID: localState?.Hotel?.ID,
      DepartmentID: menu.data?.DepartmentID,

      RoomID: menu.data?.Room?.ID,
      RequestBy: menu.data?.RequestBy,
      Title: menu.data?.Title,
      Description: menu.data?.Description,
      EstimatedCompletion: menu.data?.EstimatedCompletion,
      GalleryID: menu.data?.Gallery?.ID,
      Status: ServiceRequestStatus['Cancelled'],
      Remarks: menu.data?.Remarks,
    }
    HousekeepingServiceRequestUpdate({
      variables: {
        ServiceRequestInput: ServiceRequestInput,
      },
    }).then(i => {
      if (i.data.HousekeepingServiceRequestUpdate) {
        refetch()
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Cancelled Successfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setCancelDialog(false)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Cancel Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setCancelDialog(false)
      }
    })
  }
  const Dialog = (
    <CommonDialog
      open={CancelDialog}
      sections={{
        content: (
          <>
            <div className=" text-align-center color-text smTitle m-b-10">
              Confirm to cancel request?
            </div>
            <div
              className="m-b-10"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className="xsTitle">
                <div className="  ">{menu?.data?.Description}</div>
              </div>
            </div>
            <div className=" text-align-center color-text-2 fs-12 fw-regular m-b-10">
              {dateFormat(new Date(), 'dd mmm yyyy hh:MM TT')}
            </div>
          </>
        ),
        footer: {
          children: (
            <div className="double-button">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() => {
                  setCancelDialog(false)
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() => CancelRequest()}
              >
                Confirm
              </Button>
            </div>
          ),
        },
      }}
    />
  )
  const returnStatus = {
    Open: MoreIcon,
    InProgress: MoreIcon,
    Cancelled: CancelIcon,
    Completed: CompletedIcon,
  }
  const handleReturnStatus = Mode => returnStatus[Mode] || null
  return (
    <>
      {(loading || UpdateLoading) && <Spinner />}{' '}
      <ContentWrapper float>
        <List className="core-list">
          {FrontDeskServiceRequestListing?.length === 0 && (
            <div
              className=" xsTitle m-t-10 m-b-10"
              style={{ textAlign: 'center' }}
            >
              No Record Found.
            </div>
          )}
          {FrontDeskServiceRequestListing?.map((el, i) => (
            <ListItem key={i}>
              <ListItemAvatar>
                <Avatar
                  className="svg-icon"
                  src={handleReturnStatus(el.Status)}
                />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <span className="xsTitle fw-500 flex-space">
                      {el.Description}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc color-text-1 flex-space">
                      {dateFormat(
                        el.EstimatedCompletion,
                        'dd mmm yyyy hh:MM TT'
                      )}
                    </span>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={e => {
                    handleClick(e, el.ID, i, el)
                  }}
                >
                  <img
                    src={MoreIcon}
                    alt=""
                    width={20}
                    style={{
                      transform: 'rotate(90deg)',
                    }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
      <StyledMenu
        id="customized-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push(SERVICE_PATH.REQ_SERVICE_REQUEST_DETAIL, {
              ...menu?.data,
              Hotel: localState?.Hotel,
              Registration: localState.Registration,
              Room: localState?.Room,
            })
          }}
        >
          <span className="mdDesc">View</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(SERVICE_PATH.REQ_SERVICE_REQUEST_FORM_EDIT, {
              ...menu?.data,
              Hotel: localState?.Hotel,
              Registration: localState.Registration,
              Room: localState?.Room,
            })
          }}
        >
          <span className="mdDesc">Edit</span>
        </MenuItem>
        <MenuItem
          disabled={menu.data?.Status !== 'Open'}
          onClick={() => {
            setCancelDialog(true)
            handleClose()
          }}
        >
          <span className="mdDesc">Cancel</span>
        </MenuItem>
      </StyledMenu>
      <Fab
        color="primary"
        aria-label="add"
        className="fab"
        onClick={() =>
          history.push(SERVICE_PATH.REQ_SERVICE_REQUEST_FORM, { ...localState })
        }
      >
        <AddIcon />
      </Fab>
      {Dialog}
    </>
  )
}
