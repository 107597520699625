export const InvoiceTemplate = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <!--[if gte mso 9]>
    <xml>
      <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
      <!--[if !mso]><!-->

       <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
      <!--<![endif]-->
    <title>Invoice</title>
    <!--[if gte mso 9]>
    <style type="text/css" media="all">
      sup { font-size: 100% !important; }
    </style>
    <![endif]-->
<style type="text/css" media="print">
     
     .header-center{
             height:170px;
         position:fixed;
           top: 5px;
             }  
.print-Top{
      margin-top:170px;
     }
.print-Bottom{
margin-bottom:140px;
}
 .page-break{
       page-break-before: always;
     }
     .row-empty{
       visibility:visible;
     }
.static-row{
              position:fixed;
              width:100%;
          top: 130px;
            }
    .header-right {
  color: white !important;
  justify-content: center;
  padding:12px;
  
}
  </style>

    <style type="text/css" media="screen">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
         @media print {
          @page {
            size: A4;
          }
        }
        * {
            font-family: poppins;
            -webkit-print-color-adjust: exact;
            -webkit-text-size-adjust:none;
        }

      .row-empty{
       /* visibility:collapse; */
visibility:collapse;

     }
.header-right {
  color: white !important;
  justify-content: center;
  padding:12px;
  text-align: right;
}

      #booking {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 100%;
          margin-top: 20px;
      }

      #booking td,
      #booking th {
          border: 2px solid #2E317C;
          padding: 8px;
          font-size: 18px;
          text-align: center;
      }

      #booking tr:nth-child(even) {
          background-color: #27245F;
          color: #EEA62D;
      }
      #booking th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #333A84;
          color: white;
          font-weight: 200;
          font-size: 18px;
          text-align: center;
      }


      #description th {
          border: 2px solid #2c2c2c;
      }

      #description tr:nth-child(even) {
          background-color: #B5C3E4;
      }

      #description tr:nth-child(odd) {
          background-color: #E2E5F1;
      }

  

      #total {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
      }

      #total td,
      #total th {
          border: 2px solid #2E317C;
          padding: 8px;
          font-size: 18px;
      }

      #total tr:nth-child(even) {
          background-color: #2E317C;
          color: white;
      }

      #total tr:nth-child(odd) {
          background-color: #394693;
          color: white;
      }

      #total th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #333A84;
          color: white;
          font-weight: 200;
          font-size: 18px;
      }

      #total tfoot tr th {
          background-color: #27245F !important;
      }
    
  </style>

</head>

<body style="-webkit-text-size-adjust:none; 
-webkit-print-color-adjust: exact; background-color: white !important; ">
<div class="header-center" style="
/* background-image: url('https://hotelx-dev.ifca.io/document/invoice-header.png');  */
background-repeat: no-repeat;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
">
<div style="width:100%; display: flex;">
<div style="flex:1; padding:12px;">
<img src="!!HotelImage!!" style="width:auto; max-height: 45px; padding-top: 5px;" >
</div>
<div style="padding:12px;  color: rgb(0, 0, 0) !important;
" class="header-right">
  <div style=" font-size: 20px; font-weight: 500;">!!Note!!</div>
<div style="font-size: 10px; font-weight: bold; line-height: 1.5;width: 110px; color: #2c2959; word-wrap: break-word; padding-bottom: 10px;">
  !!InvoiceNo!!
   </div>  
    <!--   <div style="font-size: 8px; font-weight: 300; line-height: 1.5;width: 110px; word-wrap: break-word;">
  Balance Due
   </div>
    <div style="font-size: 13px; font-weight: bold; line-height: 1.5;color: #2c2959; ">
  MYR !!TotalAmount!!
   </div> -->
</div>
</div>
</div>
<div style="font-size: 10px; line-height: 1.5;width: 110px; word-wrap: break-word; padding-left: 13px; margin-top: -6px;">
<div style="font-weight: bold;">
  !!HotelName!!
</div>
<div style="width: 200px;">
  !!HotelAddress1!!
</div>
<div style="width: 200px;">
  !!HotelAddress2!!
</div>
</div>
<div style="border-bottom: solid 1px #cccccc; margin: 12px 10px;"></div>
<div style="width: 100%; display: flex; white-space: nowrap;">
<div style="width: 50%;   padding: 6px;">
    <table class="print-Top" width="100%" cellspacing="0" cellpadding="0"  style="padding:2px;">
        <tr style="margin-bottom: 6px;">
          <td style="font-size:10px; font-weight: 500; line-height: 18px; width:5%; text-align: left; color:#060965;
         ">
           Bill To
          </td>
          <td style="font-size:10px; font-weight: 400;    width:10%; vertical-align: top; text-align: left;">
          </td>
          <td style="font-size:10px; font-weight: 400;    width:50%; vertical-align: top; text-align: left;">
            
          </td>
            </tr>
            <tr style="margin-bottom: 6px;">
            <td style="font-size:10px; font-weight: 600;    color:#000000;   line-height: 18px; width:5%;">
            !!GuestName!!
            </td>
            <td style="font-size:10px; font-weight: 400;    width:10%; vertical-align: top; text-align: middle;">
            </td>
            <td style="font-size:10px; font-weight: 400;    width:50%;text-align: left;">
              </td>
              </tr>
            <tr style="margin-bottom: 6px;">
              <td style="font-size:10px; font-weight: 400;  line-height: 18px;  width:50%;text-align: left; white-space:normal;">
              !!GuestAddress1!!
              </td>
              <td style="font-size:10px; font-weight: 400;    width:10%; vertical-align: top; text-align: middle;">
              </td>
              <td style="font-size:10px; font-weight: 400;    width:5%;text-align: left;">
              
                </td>
            
                </tr>
            <tr style="margin-bottom: 6px;">
              <td style="font-size:10px; font-weight: 400;   line-height: 18px;  width:50%;text-align: left; ">
              !!GuestAddress2!!

              </td>
              <td style="font-size:10px; font-weight: 400;    width:10%; vertical-align: top; text-align: middle;">
              </td>
              <td style="font-size:10px; font-weight: 400;    width:5%; text-align: left;">
                </td>
                <!-- <td style="font-size:10px; font-weight: 400;   line-height: 18px; width:50%;">
                  Address: No 33 jalan bm 1/3 taman bukit mayang emas 47301 petaling jaya
                </td> -->
                </tr>         
                                     
  </table>
</div>
<div style="width: 50%;   padding: 6px;">
    <table class="print-Top" width="100%" cellspacing="0" cellpadding="0"  style="padding:2px;">
        <tr style="margin-bottom: 6px; vertical-align: top;">
          <td style="font-size:10px; font-weight: 400; line-height: 18px; width:60%; overflow: hidden; text-align: right; padding-right: 6px;
         ">
            !!Note!! Date
          </td>
          <td style="font-size:10px; font-weight: 400;    width:10%; vertical-align: top; text-align: middle;">
            :
          </td>
          <td style="font-size:10px; font-weight: 400;   width:50%; vertical-align: top; text-align: right;">
             !!Date!!
          </td>
            </tr>
            
                       
  </table>
</div>
</div>

<!-- <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="margin-bottom: 12px;" >
  <tr class="" style="  font-family: poppins;
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust:none;    background-color: #333A84;
  color: white;
  font-size: 10px;
  line-height: 28px;
;
      text-align: center;">
      <th style="font-weight: 300;">Arrival Date</th>
      <th style="font-weight: 300;">Departure Date</th>
      <th style="font-weight: 300;">Room No.</th>
      <th style="font-weight: 300;">Booking No.</th>
      <th style="font-weight: 300;">Cashier Name</th>
  </tr>
  <tr  style="    background-color:#2E317C;
font-family: poppins;
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust:none;
  color: #EEA62D;
  font-size: 10px;
  line-height: 28px;
;
      text-align: center;">
      <td><div style="line-height: 14px;">
         !!ArrivalDate!! 
      </div>
      <div style="color: white; font-size: 10px; font-weight: 100; line-height: 14px;">
        !!ArrivalTime!!
          </div>
      </td>
      <td><div style="line-height: 14px;">
        !!DepartureDate!! 
       </div>
       <div style="color: white; font-size: 10px; font-weight: 100; line-height: 14px;">
        !!DepartureTime!!
           </div>
       </td>
      <td>!!RoomNo!!</td>
      <td>!!BookingNo!!</td>
      <td>!!CashierName!!</td>
  </tr>
</table> -->

<table width="100%" border="0" cellspacing="0" cellpadding="0" id="description" >
<tr
style="
font-family: poppins;
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust:none;
background-color: #2c2c2c;
color: white;
font-size: 10px;
line-height: 28px;
text-align: center;
"
>
<th style="font-weight: 300; text-align: left; padding-left: 10px; width: 5px;"># </th>
<th style="font-weight: 300; text-align: left; padding-left: 10px" >Item & Description</th>
<th style="font-weight: 300; text-align: right; padding-right: 10px; color: #2c2c2c;">Amount</th>
<th style="font-weight: 300; text-align: right; padding-right: 10px; color: #2c2c2c;">Amount</th>
</tr>

</tr>
!!NewItem!!

</table>


<div style="width: 100%; display: flex;">
  <div style="width: 20%;  margin: 4px; padding: 6px;">
      <div style="display: flex; margin-bottom: 8px;">
          <!-- <img src="https://ho?al-align: -webkit-baseline-middle;font-size: 10px;">!!HotelMobileNo!!</span> -->
      </div>
    
  <div style="margin-bottom: 50px;">
      <div style="display: flex; margin-bottom: 8px;">
        <!-- <img src="https://hotelx-dev.ifca.io/document/email.png" style=" width: 20px;  margin-right: 10px;"> -->
          <!-- <span style="vertical-align: -webkit-baseline-middle;font-size: 10px; white-space: unset;">!!HotelEmail!!</span> -->
      </div>
      <div style="display: flex; margin-bottom: 8px;">
           <!-- <img src="https://hotelx-dev.ifca.io/document/website.png" style=" width: 20px;  margin-right: 10px;">
          <span style="vertical-align: -webkit-baseline-middle;font-size: 10px; white-space: unset;">!!HotelWebsite!!</span> -->
      </div>


      <div style="width:100%;">
          <!-- <img src="https://hotelx-dev.ifca.io/document/location.png" style=" width: 20px; height: auto; float: left; margin-right: 10px;">
          <div style="vertical-align: -webkit-baseline-middle;font-size: 10px;  white-space: unset;">
              !!HotelAddress!!              
            </div> -->
      </div>
  </div>


  
  </div>
  
  <div style="width: 80%; display: block; ">
      
      <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="margin-bottom: 12px; font-family: poppins;-webkit-print-color-adjust: exact;-webkit-text-size-adjust:none;" >
          <tr class="" style=" font-family: poppins;
              -webkit-print-color-adjust: exact;
              -webkit-text-size-adjust:none;     
          color: rgb(0, 0, 0);
          font-size: 10px;
          line-height: 28px;">
              <td style="font-weight: 300; padding-right: 6px; text-align: right;">Subtotal</td>
               <td style="font-weight: 300; text-align: right;padding-right: 10px;">!!Total!!</td>
          </tr>
       <!--   <tr class="" style="      background-color: #ffffff;
          color: rgb(0, 0, 0);
          font-size: 10px;
          line-height: 28px;">
              <td style="font-weight: 300; padding-right: 6px;text-align: right;">Less: Deduction</td>
              <td style="font-weight: 300; text-align: right;padding-right: 10px;">!!Deduction!!</td>
          </tr>
          <tr class="" style=" font-family: poppins;-webkit-print-color-adjust: exact;-webkit-text-size-adjust:none;background-color: #ffffff;color: rgb(0, 0, 0);
          font-size: 10px;
          line-height: 28px;">
              <td style="font-weight: 300; padding-right: 6px;text-align: right;">Total Billing</td>
               <td style="font-weight: 300; text-align: right;padding-right: 10px;">!!TotalBilling!!</td>
          </tr>
          <tr class="" style="background-color: #ffffff;
          color: rgb(0, 0, 0);
          font-size: 10px;
          line-height: 28px;">
              <td style="font-weight: 300; padding-right: 6px;text-align: right;">Service Charge & Gov Tax</td>
              <td style="font-weight: 300; text-align: right;padding-right: 10px;">!!ServiceChargeGovTax!!</td>
          </tr> -->
        
      <tr class="" style="     
      color: rgb(0, 0, 0);
      font-size: 10px;
line-height: 28px;">
              <td style="font-weight: bold; padding-right: 6px;text-align: right;">Total </td>
                 <td style="font-weight: bold; text-align: right; padding-right: 10px;
  color: #000000;
                 ">$ !!TotalAmount!!</td>
          </tr>
      </table>
  </div>
  
  </div>

<div
style="font-style: italic; font-weight: 500; color: #2c2c2c; font-size: 12px; text-align: center; margin-top: 8px;">
Thank you for Staying with us</div>
<div>
</div>
<!-- <div style=" display: flex; margin-top: 30px; background-color: #333A84; padding:12px; ">
<span style="color:white; font-size: 12px; flex: 1; font-weight: 300;">
  This Folio is computer-generated, no signature is required.
</span>

</div> -->
 


</body>

</html>`