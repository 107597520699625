import { ContentWrapper } from '@ifca-ui/neumorphism'
import { Button, TextareaAutosize } from '@material-ui/core'
import { ArrowRightAlt } from '@material-ui/icons'
import { Rating } from '@material-ui/lab'
import CommentIcon from 'assets/svg/rating/comment.svg'
import Facilitiy from 'assets/svg/rating/rate-facility.svg'
import Location from 'assets/svg/rating/rate-location.svg'
import OverAll from 'assets/svg/rating/rate-overall.svg'
import PropertyCondition from 'assets/svg/rating/rate-property-condition.svg'
import Rooms from 'assets/svg/rating/rate-room.svg'
import Service from 'assets/svg/rating/rate-service.svg'
import RatingIcon from 'assets/svg/rating/rating.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { useHotelRatingInsertMutation } from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { BOOKING_PATH } from '../Booking/Routes'

interface Props {
  Open?: boolean
  Cleanliness?: number
  Facilities: number
  Location?: number
  ComfortQuality?: number
  Service?: number
  OverallExperience?: number
  Step?: number
  Comment?: string
}
export const RateStay: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        header: {
          headerMode: 'secondary',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialState: Props = {
    Open: false,
    Cleanliness: 0,
    Facilities: 0,
    Location: 0,
    ComfortQuality: 0,
    Service: 0,
    OverallExperience: 0,
    Step: 0,
    Comment: '',
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const handleSkip = () => history.push(BOOKING_PATH.ROOT)

  const handleNext = () => {
    dispatch({ type: 'Step', payload: state.Step + 1 })
  }

  const handleBack = () => {
    dispatch({ type: 'Step', payload: state.Step - 1 })
  }
  const [HotelRatingInsert, { loading }] = useHotelRatingInsertMutation()

  const handleSubmit = () => {
    HotelRatingInsert({
      variables: {
        input: {
          HotelID: localState?.HotelDetails?.ID || localState?.Hotel?.ID,
          BookingID:
            localState?.RegistrationDetails?.Booking?.ID || localState?.ID,
          Cleanliness: state.Cleanliness,
          Facilities: state.Facilities,
          Location: state.Location,
          ComfortQuality: state.ComfortQuality,
          Service: state.Service,
          OverallExperience: state.OverallExperience,
          ReviewDate: new Date(),
          Comments: state.Comment,
        },
      },
    }).then(i => {
      if (i.data.HotelRatingInsert) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Rate stay Succesfully',
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(BOOKING_PATH.ROOT)
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Rate Stay Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    })
  }
  const handleContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="">
            <div className="label text-noflow fs-30 fw-600 m-b-12">Rate Us</div>
            <div className="label text-noflow fs-12 fw-medium m-b-12">
              Let us know what you think
            </div>
            <div className="label text-noflow fs-12 fw-medium m-b-30">
              and we help you to improve!
            </div>
            <div className="p-12 text-align-center m-t-20 m-b-20">
              <img
                src={RatingIcon}
                alt="rate"
                style={{
                  width: '100%',
                  maxWidth: '500px',
                }}
              />
            </div>
            <div
              className=" p-12 flex-width"
              style={{
                position: 'fixed',
                // display: 'flex',
                bottom: '36px',
                right: 0,
              }}
            >
              <span
                className="fs-16 fw-regular flex-space m-l-8"
                onClick={handleSkip}
              >
                Skip
              </span>
              <span
                className="xsTitle icon-text fs-16"
                // style={{ marginRight: '25px' }}
                onClick={handleNext}
              >
                Let's Go{' '}
                <span className="m-l-4">
                  <ArrowRightAlt style={{ width: '24px' }} />
                </span>
              </span>
            </div>
          </div>
        )
      case 1:
        return (
          <>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <img
                src={PropertyCondition}
                alt=""
                style={{ width: '100%', height: '25vh' }}
              />
              <div className="xsTitle m-b-16 fs-18 m-t-10">
                Property condition / cleanliness
              </div>
              <div className="desc m-b-12 fs-14">
                Tap a star to give your rating
              </div>
              <div className="m-b-40 rating">
                <Rating
                  value={state.Cleanliness}
                  onChange={(event, newValue) => {
                    dispatch({ type: 'Cleanliness', payload: newValue })
                  }}
                />
              </div>
              <div className="">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="common-button "
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <img
                src={Facilitiy}
                alt=""
                style={{ width: '100%', height: '25vh' }}
              />
              <div
                className="xsTitle m-b-16 fs-18 m-t-10"
                style={{ lineHeight: '20px' }}
              >
                Facilities
              </div>
              <div className="desc m-b-12 fs-14 ">
                Tap a star to give your rating
              </div>
              <div className="m-b-40 rating">
                <Rating
                  value={state.Facilities}
                  onChange={(event, newValue) => {
                    dispatch({ type: 'Facilities', payload: newValue })
                  }}
                />
              </div>
              <div className="">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={handleBack}
                  className="common-button m-r-10"
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="common-button m-l-10"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )
      case 3:
        return (
          <>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <img
                src={Location}
                alt=""
                style={{ width: '100%', height: '25vh' }}
              />
              <div
                className="xsTitle m-b-16 fs-18 m-t-10"
                style={{ lineHeight: '20px' }}
              >
                Location
              </div>
              <div className="desc m-b-12 fs-14">
                Tap a star to give your rating
              </div>
              <div className="m-b-40 rating">
                <Rating
                  value={state.Location}
                  onChange={(event, newValue) => {
                    dispatch({ type: 'Location', payload: newValue })
                  }}
                />
              </div>
              <div className="">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={handleBack}
                  className="common-button m-r-10"
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="common-button m-l-10"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )
      case 4:
        return (
          <>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <img
                src={Rooms}
                alt=""
                style={{ width: '100%', height: '25vh' }}
              />
              <div
                className="xsTitle m-b-16 fs-18 m-t-10"
                style={{ lineHeight: '20px' }}
              >
                Room comfort and quality
              </div>
              <div className="desc m-b-12 fs-14">
                Tap a star to give your rating
              </div>
              <div className="m-b-40 rating">
                <Rating
                  value={state.ComfortQuality}
                  onChange={(event, newValue) => {
                    dispatch({ type: 'ComfortQuality', payload: newValue })
                  }}
                />
              </div>
              <div className="">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={handleBack}
                  className="common-button m-r-10"
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="common-button m-l-10"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )
      case 5:
        return (
          <>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <img
                src={Service}
                alt=""
                style={{ width: '100%', height: '25vh' }}
              />
              <div
                className="xsTitle m-b-16 fs-18 m-t-10"
                style={{ lineHeight: '20px' }}
              >
                Service
              </div>
              <div className="desc m-b-12 fs-14">
                Tap a star to give your rating
              </div>
              <div className="m-b-40 rating">
                <Rating
                  value={state.Service}
                  onChange={(event, newValue) => {
                    dispatch({ type: 'Service', payload: newValue })
                  }}
                />
              </div>
              <div className="">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={handleBack}
                  className="common-button m-r-10"
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="common-button m-l-10"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )
      case 6:
        return (
          <>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <img
                src={OverAll}
                alt=""
                style={{ width: '100%', height: '25vh' }}
              />
              <div
                className="xsTitle m-b-16 fs-18 m-t-10"
                style={{ lineHeight: '20px' }}
              >
                Overall Experience
              </div>
              <div className="desc m-b-12 fs-14">
                Tap a star to give your rating
              </div>
              <div className="m-b-12 rating">
                <Rating
                  value={state.OverallExperience}
                  onChange={(_, newValue) => {
                    dispatch({ type: 'OverallExperience', payload: newValue })
                  }}
                />
              </div>
              {state.Open ? (
                <div className="rating-comment">
                  <TextareaAutosize
                    aria-label="Comment"
                    placeholder="Comment here"
                    value={state.Comment}
                    onChange={e => {
                      dispatch({
                        type: 'Comment',
                        payload: e.target.value,
                      })
                    }}
                  />
                </div>
              ) : (
                <div
                  className="desc m-b-40 fs-14 icon-text text-align-center color-black"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  onClick={() => dispatch({ type: 'Open', payload: true })}
                >
                  <img src={CommentIcon} alt="" className="m-r-4 " />
                  Add a comment
                </div>
              )}
              <div className="">
                {/* <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={handleBack}
                  className="common-button m-r-10"
                >
                  Previous
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="common-button "
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </>
        )
      default:
        return null
    }
  }
  return (
    <>
      <ContentWrapper>{handleContent(state.Step)}</ContentWrapper>
    </>
  )
}
