import {
  ContentWrapper,
  Spinner,
  StyledCardContent,
} from '@ifca-ui/neumorphism'
import Rating from '@material-ui/lab/Rating'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import React, { FC, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useHotelReviewListingQuery } from '../../../../generated/graphql'
import { BOOKING_PATH } from '../Routes'
import dateFormat from 'dateformat'
import { RatingAvg } from 'containers/Utils/RatingAvg'

export const RatingReview: FC = () => {
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    // refetch,
    loading,
    data: { HotelReviewListing } = { HotelReviewListing: [] },
  } = useHotelReviewListingQuery({
    variables: {
      HotelID: localState?.HotelDetail?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Review',
          props: {
            onClick: () =>
              history.push(BOOKING_PATH.HOTEL_DETAIL, { ...localState }),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading && <Spinner />}
      <ContentWrapper>
        <StyledCardContent>
          {HotelReviewListing?.filter(el => el.IsActive)?.map((x, i) => (
            <div className="m-b-15" key={i}>
              <Rating
                defaultValue={RatingAvg(x)}
                size="small"
                name="rating"
                readOnly
              />
              <div className="smTitle m-b-4">
                {x.Booking?.Contact?.FullName}
              </div>
              <div className="mdLabel color-text-1 m-b-4 text-noflow">
                {x.Comments || 'No Comment.'}
              </div>
              <div className="desc color-text-2 m-b-4">
                {dateFormat(x.ReviewDate, 'dd mmm yyyy')}
              </div>
            </div>
          ))}
        </StyledCardContent>
      </ContentWrapper>
    </>
  )
}
