import { DateRangePicker } from '@ifca-ui/neumorphism'
import { IconButton, Popover } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
export const CheckInOutDate = props => {
  const { CheckInDateOpen, state, dispatch } = props

  return (
    <Popover
      open={CheckInDateOpen}
      anchorEl={state.CheckInDateAnchorEl}
      onClose={() => dispatch({ type: 'CheckInDateAnchorEl', payload: null })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="date-wrapper">
        <DateRangePicker
          disablePrev={state.disabledPrevMonth}
          navPrev={
            <IconButton
              size="small"
              onClick={e => {
                if (state.disabledPrevMonth) {
                  // Stop propagation of button and prevent next month from loading
                  e.stopPropagation()
                } else {
                  // do nothing..
                }
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          }
          navNext={
            <IconButton size="small">
              <KeyboardArrowRightIcon />
            </IconButton>
          }
          startDate={state.CheckInDate}
          endDate={state.CheckOutDate}
          onPrevMonthClick={month => {
            let checkMonth = moment(month, 'YYYY/MM/DD')

            let selectedMonth = Number(checkMonth.format('M'))
            let newDateMonth = new Date().getMonth() + 1
            console.log(selectedMonth, newDateMonth)
            if (
              selectedMonth === newDateMonth ||
              selectedMonth <= newDateMonth
            ) {
              dispatch({
                type: 'disabledPrevMonth',
                payload: true,
              })
            }
          }}
          onNextMonthClick={month => {
            let checkMonth = moment(month, 'YYYY/MM/DD')

            let selectedMonth = Number(checkMonth.format('M'))
            let newDateMonth = new Date().getMonth() + 1
            console.log(selectedMonth, newDateMonth)
            if (selectedMonth > newDateMonth) {
              dispatch({
                type: 'disabledPrevMonth',
                payload: false,
              })
            }
          }}
          onDatesChange={({ startDate, endDate }) => {
            // console.log(startDate)
            dispatch({
              type: 'CheckInDate',
              payload: startDate,
            })
            dispatch({
              type: 'CheckOutDate',
              payload: endDate,
            })
            if (state.Focus !== 'startDate') {
              dispatch({ type: 'CheckInDateAnchorEl', payload: null })
            }
            // const lastmonthfirstdate = moment(startDate)
            //   .subtract(1, 'months')
            //   .endOf('month')
            //   .format('DD-MM-YYYY')
            // console.log(moment(new Date()).isBefore(moment(startDate), 'month'))
          }}
          focusedInput={state.Focus}
          onFocusChange={focusedInput => {
            console.log(focusedInput)
            dispatch({
              type: 'Focus',
              payload: focusedInput || 'startDate',
            })
          }}
        />{' '}
      </div>
    </Popover>
  )
}
