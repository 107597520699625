import { ContentWrapper } from '@ifca-ui/neumorphism'
import DefaultImg from 'assets/images/default-img.png'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import React, { FC, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SERVICE_PATH } from '../Routes'
SwiperCore.use([Pagination])

export const DirectoriesDetail: FC = () => {
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: localState?.Detail?.Title,
          props: {
            onClick: () =>
              history.push(SERVICE_PATH.DIRECTORIES, { ...localState }),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState])

  return (
    <>
      <ContentWrapper>
        <Swiper pagination={true} className="basic-swiper m-b-15">
          {localState?.Detail?.FacilityGallery &&
            localState?.Detail?.FacilityGallery?.map((v: any, i: number) => (
              <SwiperSlide key={i}>
                <img src={v.Gallery?.ImageURL || DefaultImg} alt="" />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="mdLabel m-b-14 flex-width text-noflow">
          {localState?.Detail?.Description}
        </div>
        <div className=" m-b-14  ">
          <div className="smTitle fw-bold">Location</div>
          <div className="mdDesc">Level {localState?.Detail?.Level}</div>
        </div>
        <div className=" m-b-14  ">
          <div className="smTitle fw-bold">Operation Hour</div>
          <div className="mdDesc">-</div>
        </div>
      </ContentWrapper>
    </>
  )
}
