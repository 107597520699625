import { ContentWrapper, Spinner } from '@ifca-ui/neumorphism'
import {
  Avatar,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from 'assets/svg/cancel.svg'
import CompletedIcon from 'assets/svg/completed.svg'
import MoreIcon from 'assets/svg/more.svg'
import BigNumber from 'bignumber.js'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { amtStr } from 'containers/Utils/numFormatter'
import dateFormat from 'dateformat'
import { useGuestRoomServiceListingQuery } from 'generated/graphql'
import React, { FC, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { SERVICE_PATH } from '../Routes'

interface Props {
  ReqType?: string
}
export const Request: FC<Props> = ({ ReqType }) => {
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    loading,
    data: { GuestRoomServiceListing } = { GuestRoomServiceListing: [] },
  } = useGuestRoomServiceListingQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
      RegistrationID: localState.Registration?.ID,
      ServiceType: ReqType,
    },
    fetchPolicy: 'no-cache',
  })
  const ReqLabel = ReqType === 'FnB' ? 'Room Service' : 'Online Shop'
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: ReqLabel,
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading && <Spinner />}{' '}
      <ContentWrapper>
        <List className="core-list">
          {GuestRoomServiceListing?.length === 0 && (
            <div
              className=" xsTitle m-t-10 m-b-10"
              style={{ textAlign: 'center' }}
            >
              No Record Found.
            </div>
          )}
          {GuestRoomServiceListing?.filter(x =>
            new BigNumber(x.BaseAmount).isPositive()
          )?.map((el: any, i: number) => (
            <ListItem key={i}>
              <ListItemAvatar>
                <Avatar
                  className="svg-icon"
                  src={
                    el?.IsDelivered
                      ? CompletedIcon
                      : !el.IsDelivered && el.ReversedBillLedgerID !== null
                      ? CancelIcon
                      : MoreIcon
                  }
                />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <span className="xsTitle fw-500 flex-space">
                      {el.IncidentalCharge?.Description}
                    </span>
                    <span className="mdLabel icon-text">
                      {' '}
                      $ {amtStr(el.TrxAmount || 0)}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc color-text-1 flex-space">
                      {dateFormat(el.TrxDate, 'dd mmm yyyy hh:MM TT')}
                    </span>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
      <Fab
        color="primary"
        aria-label="add"
        className="fab"
        onClick={() =>
          history.push(
            ReqType === 'FnB'
              ? SERVICE_PATH.REQ_ROOM_SERVICE_FORM
              : SERVICE_PATH.REQ_ONLINE_SHOP_FORM,
            { ...localState }
          )
        }
      >
        <AddIcon />
      </Fab>
    </>
  )
}
