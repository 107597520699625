import React, { FC, useContext, useEffect } from 'react'
import {
  CommonDialog,
  ContentWrapper,
  PromoSwiper,
  QuickAccess,
  RoomInfo,
  Spinner,
  StyledCardContent,
} from '@ifca-ui/neumorphism'
import DefaultImg from 'assets/images/default-img.png'
import { useHistory, useLocation } from 'react-router'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { SERVICE_PATH } from '../Routes'

export const ServiceRequestDetail: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Detail',
          props: {
            onClick: () => {
              history.push(SERVICE_PATH.REQ_SERVICE_REQUEST, { ...localState })
            },
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const AudioDocs = localState?.ServiceRequestGallery?.filter(
    x =>
      x?.Gallery?.BucketFileName.split('.')[
        x?.Gallery?.BucketFileName.split('.')?.length - 1
      ] === 'blob'
  )[0]
  const OtherDocs = localState?.ServiceRequestGallery?.filter(
    x =>
      x?.Gallery?.BucketFileName.split('.')[
        x?.Gallery?.BucketFileName.split('.')?.length - 1
      ] !== 'blob'
  )
  return (
    <>
      <ContentWrapper>
        <StyledCardContent>
          <div className="desc flex-width m-b-4">Description</div>
          <div className="xsTitle flex-width m-b-12">
            {localState?.Description}
          </div>
          {AudioDocs && (
            <>
              <audio
                id="videoSource"
                controls
                src={AudioDocs?.Gallery?.ImageURL}
              />
            </>
          )}
          <div className="  m-t-6 m-b-14">
            {OtherDocs?.map((x, i) => (
              <div className="image-preview" key={i}>
                <img src={x.Gallery?.ImageURL} alt="" />
              </div>
            ))}
          </div>
        </StyledCardContent>
      </ContentWrapper>
    </>
  )
}
