import React, { lazy } from 'react'
const Services = lazy(() =>
  import('./Services').then(module => ({
    default: module.Services,
  }))
)
const Directories = lazy(() =>
  import('./Directories/Directories').then(module => ({
    default: module.Directories,
  }))
)
const DirectoriesDetail = lazy(() =>
  import('./Directories/DirectoriesDetail').then(module => ({
    default: module.DirectoriesDetail,
  }))
)
const ServiceRequest = lazy(() =>
  import('./Request').then(module => ({
    default: module.ServiceRequest,
  }))
)
const ServiceRequestForm = lazy(() =>
  import('./Request').then(module => ({
    default: module.ServiceRequestForm,
  }))
)
const ServiceRequestDetail = lazy(() =>
  import('./Request').then(module => ({
    default: module.ServiceRequestDetail,
  }))
)
const Request = lazy(() =>
  import('./Request').then(module => ({
    default: module.Request,
  }))
)
const Order = lazy(() =>
  import('./Request').then(module => ({
    default: module.Order,
  }))
)
const Summary = lazy(() =>
  import('./Request').then(module => ({
    default: module.Summary,
  }))
)
const PackageRedemption = lazy(() =>
  import('./PackageRedemption').then(module => ({
    default: module.PackageRedemption,
  }))
)
const LateCheckOut = lazy(() =>
  import('./LateCheckOut').then(module => ({
    default: module.LateCheckOut,
  }))
)

const ExtendStay = lazy(() =>
  import('./ExtendStay').then(module => ({
    default: module.ExtendStay,
  }))
)
const Bills = lazy(() =>
  import('./Bills').then(module => ({
    default: module.Bills,
  }))
)
const InvoiceDocument = lazy(() =>
  import('./InvoiceDocument').then(module => ({
    default: module.InvoiceDocument,
  }))
)
const RateStay = lazy(() =>
  import('./RateStay').then(module => ({
    default: module.RateStay,
  }))
)
const AdsDetail = lazy(() =>
  import('./AdsDetail').then(module => ({
    default: module.AdsDetail,
  }))
)
const QRpasscode = lazy(() =>
  import('./QRpasscode').then(module => ({
    default: module.QRpasscode,
  }))
)

const Hascheckout = lazy(() =>
  import('./Checkout').then(module => ({
    default: module.Checkout,
  }))
)
//! Room services + Online Shop same file
export const SERVICE_PATH = {
  LIST: '/services',
  SERVICE_LIST_PAYMENT: '/services/redirect/:status',
  DIRECTORIES: '/services/directories',
  DIRECTORIES_DETAIL: '/services/directories/detail',
  REQ_SERVICE_REQUEST: '/services/services-request',
  REQ_SERVICE_REQUEST_FORM: '/services/services-request/add',
  REQ_SERVICE_REQUEST_FORM_EDIT: '/services/services-request/edit',
  REQ_SERVICE_REQUEST_DETAIL: '/services/services-request/detail',
  REQ_ROOM_SERVICE: '/services/room-service',
  REQ_ROOM_SERVICE_FORM: '/services/room-service/order',
  REQ_ROOM_SERVICE_FORM_SUMMARY: '/services/room-service/order/summary',
  REQ_ONLINE_SHOP: '/services/online-shop',
  REQ_ONLINE_SHOP_FORM: '/services/online-shop/order',
  REQ_ONLINE_SHOP_FORM_SUMMARY: '/services/online-shop/order/summary',
  PACKAGE_REDEMPTION: '/services/package-redemption',
  LATE_CHECKOUT: '/services/late-checkout',
  EXTEND_STAY: '/services/extend-stay',
  BILLS: '/services/bills',
  CHECKOUT_BILLS: '/services/checkout-bills',
  QRpasscode: '/services/QRpasscode',
  CHECKOUT_BILLS_PAYMENT: '/services/checkout-bills/redirect/:status',
  BILLS_INVOICE: '/services/bills/invoice',
  RATING: '/services/checkout/rating',
  MARKETING_ADS: '/services/ads-detail',
  HAS_CHECKOUT: '/services/hascheckout',
}
const ServicesRoutes = [
  {
    props: { exact: true, path: SERVICE_PATH.LIST },
    component: <Services />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.SERVICE_LIST_PAYMENT },
    component: <Services />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.MARKETING_ADS },
    component: <AdsDetail />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.PACKAGE_REDEMPTION },
    component: <PackageRedemption />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.LATE_CHECKOUT },
    component: <LateCheckOut />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.EXTEND_STAY },
    component: <ExtendStay />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.BILLS },
    component: <Bills Mode={'Bill'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.CHECKOUT_BILLS },
    component: <Bills Mode={'CheckOut'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.CHECKOUT_BILLS_PAYMENT },
    component: <Bills Mode={'CheckOut'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.BILLS_INVOICE },
    component: <InvoiceDocument Mode={'Service'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.RATING },
    component: <RateStay />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.DIRECTORIES },
    component: <Directories />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.DIRECTORIES_DETAIL },
    component: <DirectoriesDetail />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_SERVICE_REQUEST },
    component: <ServiceRequest />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_SERVICE_REQUEST_FORM },
    component: <ServiceRequestForm FormMode="Add" />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_SERVICE_REQUEST_FORM_EDIT },
    component: <ServiceRequestForm FormMode="Edit" />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_SERVICE_REQUEST_DETAIL },
    component: <ServiceRequestDetail />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_ROOM_SERVICE },
    component: <Request ReqType={'FnB'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_ONLINE_SHOP },
    component: <Request ReqType={'SHOP'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_ROOM_SERVICE_FORM },
    component: <Order ReqType={'FnB'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_ONLINE_SHOP_FORM },
    component: <Order ReqType={'SHOP'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_ROOM_SERVICE_FORM_SUMMARY },
    component: <Summary ReqType={'FnB'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.REQ_ONLINE_SHOP_FORM_SUMMARY },
    component: <Summary ReqType={'SHOP'} />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.QRpasscode },
    component: <QRpasscode />,
  },
  {
    props: { exact: true, path: SERVICE_PATH.HAS_CHECKOUT },
    component: <Hascheckout />,
  },
]

export default ServicesRoutes
