import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
interface props {
  type?: string
}
const BookingList = ({ ...props }) => (
  <ListItem {...props}>
    <ListItemAvatar>
      <Skeleton variant="circle" width={40} height={40} />
    </ListItemAvatar>
    <ListItemText
      primary={
        <>
          <span className="xsTitle fw-500 flex-space">
            <Skeleton variant="text" width={100} height={15} />
          </span>
        </>
      }
      secondary={
        <span>
          <span className="desc flex-space">
            <Skeleton variant="text" width={150} height={15} />
          </span>
        </span>
      }
    />
  </ListItem>
)
const HotelList = ({ ...props }) => (
  <ListItem {...props} className="hotel">
    <ListItemAvatar>
      <Skeleton variant="rect" width={80} height={80} />
    </ListItemAvatar>
    <ListItemText
      primary={
        <>
          <span className="xsTitle fw-500 flex-space">
            <Skeleton variant="text" width={60} height={10} />
          </span>
        </>
      }
      secondary={
        <>
          <span className="desc flex-space">
            <Skeleton variant="text" width={150} height={10} />
          </span>
          <span
            className="flex-width m-t-6"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Skeleton variant="rect" width={150} height={10} />
            </div>
            <span className="fs-14 fw-bold">
              <Skeleton variant="text" width={60} height={10} />
            </span>
          </span>
        </>
      }
    />
  </ListItem>
)
export default function SkeletonList({ type }: props) {
  const list = [0, 1, 2, 3, 4, 5]
  const handleListRender = (type, index) => {
    let output
    switch (type) {
      case 'Booking':
        output = <BookingList key={index} />
        break
      case 'Hotel':
        output = <HotelList key={index} />
        break

      default:
        output = null
    }
    return output
  }
  return <>{list?.map((_, i) => handleListRender(type, i))}</>
}
