import { ContentWrapper, LeftArrow } from '@ifca-ui/neumorphism'
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import HotelIcon from 'assets/svg/account/about-hotel.svg'
import CookiePolicyIcon from 'assets/svg/account/cookie-policy.svg'
import PrivacyPolicyIcon from 'assets/svg/account/privacy-policy.svg'
import TermInUseIcon from 'assets/svg/account/term-in-use.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import React, { FC, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
export const AboutUs: FC = () => {
  const history = useHistory()

  const { RootDispatch } = useContext<AppContextProps>(AppContext)

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'About Us',
          props: {
            onClick: () => history.goBack(),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const AboutUsList = [
    {
      Icon: PrivacyPolicyIcon,
      Title: 'Privacy Policy',
    },
    {
      Icon: CookiePolicyIcon,
      Title: 'Cookie Policy',
    },
    {
      Icon: TermInUseIcon,
      Title: 'Term of Use',
    },
  ]
  return (
    <>
      <ContentWrapper>
        <div className="m-b-14 about-us-wrapper">
          <Avatar alt="companylogo" src={HotelIcon} />
          <div className="m-t-8">
            <div className="xsTitle">All material here in 2011-2021</div>
            <div className="xsTitle">Hotelx.Sdn.Bhd. All Rights Reserved.</div>
          </div>
        </div>
        <List className="core-list">
          {AboutUsList?.map((el, i) => (
            <ListItem key={i}>
              <ListItemAvatar>
                <Avatar className="svg-icon" src={el.Icon} />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <span className="xsTitle fw-500 flex-space">
                      {el.Title}
                    </span>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={e => {}}>
                  <LeftArrow
                    style={{
                      transform: 'rotate(180deg)',
                    }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
    </>
  )
}
