import { Popover } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import { BOOKING_PATH } from '../Routes'

export const AddPopOver = ({
  ID,
  Open,
  state,
  dispatch,
  FolioListingByRegistration,
  localState,
}: any) => {
  const history = useHistory()

  return (
    <Popover
      className="filter-popover"
      id={ID}
      open={Open}
      anchorEl={state.AnchorEl}
      onClose={() => {
        dispatch({ type: 'AnchorEl', payload: null })
        dispatch({ type: 'IndexSelected', payload: null })
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          padding: '12px',
        },
      }}
    >
      {state.IsInvoice ? (
        <>
          {FolioListingByRegistration?.map((el, i) => (
            <div
              key={i}
              className="xsTitle pointer m-b-12"
              onClick={() =>
                history.push(BOOKING_PATH.BOOKING_INVOICE, {
                  ...el,
                  localState: localState,
                })
              }
            >
              Invoice #{el.FolioNo}
            </div>
          ))}
        </>
      ) : (
        <div
          className="xsTitle pointer"
          onClick={() => {
            dispatch({ type: 'AddDialog', payload: {} })
            dispatch({ type: 'AnchorEl', payload: null })
          }}
        >
          {' '}
          Add Guest{' '}
        </div>
      )}
    </Popover>
  )
}
