import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Grid, ListItemAvatar, ListItemText } from '@material-ui/core'
import { StyledCardContent } from '@ifca-ui/neumorphism'
interface props {
  type?: string
}
const BookingDetailImg = () => (
  <StyledCardContent>
    <div className="rm-padding-card ">
      <Skeleton variant="rect" width={335} height={80} />
    </div>
  </StyledCardContent>
)
const BookingInfo = () => {
  return (
    <StyledCardContent>
      <Grid
        container
        justify="flex-start"
        className="m-b-16"
        style={{ textAlign: 'center' }}
      >
        <Grid item xs={4}>
          <div className="desc color-text-2 m-b-4">
            {' '}
            <Skeleton variant="text" width={50} height={10} />
          </div>
          <div className="title ">
            <Skeleton variant="text" width={100} height={10} />
          </div>
        </Grid>
        <Grid item xs={4} className="auto-margin">
          {/* <div className="auto-margin stay-duration"> */}
          <Skeleton variant="text" width={50} height={10} />
          {/* </div> */}
        </Grid>
        <Grid item xs={4}>
          <div className="desc color-text-2 m-b-4">
            {' '}
            <Skeleton variant="text" width={50} height={10} />
          </div>
          <div className="title ">
            <Skeleton variant="text" width={100} height={10} />
          </div>
        </Grid>
      </Grid>
      <div className="flex-width color-text-1 m-b-8 mdDesc">
        <span className=" flex-space">
          {' '}
          <Skeleton variant="text" width={60} height={10} />
        </span>
        <span className="">
          {' '}
          <Skeleton variant="text" width={40} height={10} />
        </span>
      </div>
      <div className="flex-width color-text-1 m-b-8 mdDesc">
        <span className=" flex-space">
          {' '}
          <Skeleton variant="text" width={60} height={10} />
        </span>
        <span className="">
          <Skeleton variant="text" width={40} height={10} />
        </span>
      </div>
      <div className="flex-width color-text-1 m-b-8 mdDesc">
        <span className=" flex-space">
          {' '}
          <Skeleton variant="text" width={60} height={10} />
        </span>
        <span className="">
          <Skeleton variant="text" width={40} height={10} />
        </span>
      </div>
    </StyledCardContent>
  )
}
export default function SkeletonCard({ type }: props) {
  const BookingDetail = {
    BackgroundImg: 'BackgroundImg',
    BookingInfo: 'BookingInfo',
  }

  const handleListRender = type => {
    let output
    switch (type) {
      case BookingDetail.BackgroundImg:
        output = <BookingDetailImg />
        break
      case BookingDetail.BookingInfo:
        output = <BookingInfo />
        break
      default:
        output = null
    }
    return output
  }
  return <>{handleListRender(type)}</>
}
