import { CommonDialog } from '@ifca-ui/neumorphism'
import { Box } from '@material-ui/core'
import React from 'react'
import OtpInput from 'react-otp-input'
export const OTPDialog = ({
  OTPOpen,
  setOTPOpen,
  OTP,
  setOTP,
  MobileNo,
  sendOTPRequest,
}) => {
  return (
    <CommonDialog
      open={OTPOpen}
      onClose={() => setOTPOpen(false)}
      sections={{
        content: (
          <>
            <Box width="100%" justifyContent="center" textAlign="center">
              <Box className="xsTitle">
                We've sent 4 code via SMS to {MobileNo}.
              </Box>
              <Box className="desc" pt="10px" color="#7681A1">
                Enter your OTP Code here.{' '}
              </Box>
              <Box p="15px 0px" width={'100%'} className="otp-input">
                <OtpInput
                  value={OTP}
                  onChange={otp => setOTP(otp)}
                  numInputs={4}
                  placeholder="6666"
                  // separator={<span>-</span>}
                />
              </Box>

              {/* <OtpContainer>
            <OtpInput
              value={otp}
              onChange={otp => setOtp(otp)}
              numInputs={4}
              placeholder="6666"
              // separator={<span>-</span>}
            />
          </OtpContainer> */}
              <Box width="100%" mt="10px">
                <Box
                  className="desc"
                  textAlign="center"
                  pt="10px"
                  color="#7681A1"
                >
                  Didn't receive the SMS?
                </Box>
                <Box
                  component="u"
                  fontSize={'12px'}
                  fontWeight={'700'}
                  textAlign="center"
                  pt="5px"
                  color="primary"
                  onClick={() => {
                    sendOTPRequest()
                  }}
                >
                  Resend Now
                </Box>
              </Box>
            </Box>
          </>
        ),
      }}
    />
  )
}
