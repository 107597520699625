export const RatingAvg = (Rating: any) => {
    let FiveStar = 0,
      FourStar = 0,
      ThreeStar = 0,
      TwoStar = 0,
      OneStar = 0
    //Five Start
    FiveStar += Rating?.Cleanliness === 5 ? 1 : 0
    FiveStar += Rating?.Facilities === 5 ? 1 : 0
    FiveStar += Rating?.Location === 5 ? 1 : 0
    FiveStar += Rating?.ComfortQuality === 5 ? 1 : 0
    FiveStar += Rating?.Service === 5 ? 1 : 0
    FiveStar += Rating?.OverallExperience === 5 ? 1 : 0

    FourStar += Rating?.Cleanliness === 4 ? 1 : 0
    FourStar += Rating?.Facilities === 4 ? 1 : 0
    FourStar += Rating?.Location === 4 ? 1 : 0
    FourStar += Rating?.ComfortQuality === 4 ? 1 : 0
    FourStar += Rating?.Service === 4 ? 1 : 0
    FourStar += Rating?.OverallExperience === 4 ? 1 : 0

    ThreeStar += Rating?.Cleanliness === 4 ? 1 : 0
    ThreeStar += Rating?.Facilities === 4 ? 1 : 0
    ThreeStar += Rating?.Location === 4 ? 1 : 0
    ThreeStar += Rating?.ComfortQuality === 4 ? 1 : 0
    ThreeStar += Rating?.Service === 4 ? 1 : 0
    ThreeStar += Rating?.OverallExperience === 4 ? 1 : 0

    TwoStar += Rating?.Cleanliness === 4 ? 1 : 0
    TwoStar += Rating?.Facilities === 4 ? 1 : 0
    TwoStar += Rating?.Location === 4 ? 1 : 0
    TwoStar += Rating?.ComfortQuality === 4 ? 1 : 0
    TwoStar += Rating?.Service === 4 ? 1 : 0
    TwoStar += Rating?.OverallExperience === 4 ? 1 : 0

    OneStar += Rating?.Cleanliness === 4 ? 1 : 0
    OneStar += Rating?.Facilities === 4 ? 1 : 0
    OneStar += Rating?.Location === 4 ? 1 : 0
    OneStar += Rating?.ComfortQuality === 4 ? 1 : 0
    OneStar += Rating?.Service === 4 ? 1 : 0
    OneStar += Rating?.OverallExperience === 4 ? 1 : 0

    let Result =
      (Number(FiveStar * 5) +
        Number(FourStar * 4) +
        Number(ThreeStar * 3) +
        Number(TwoStar * 2) +
        Number(OneStar * 1)) /
        (FiveStar + FourStar + ThreeStar + TwoStar + OneStar) || 0

    return Result
  }