import { MouseEvent, useState } from 'react'
/**
 * @param MenuOption
 * handle moreoption icon on listing page
 */
export const useMenuOption = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState({ ID: '', index: 0, data: null })
  const handleClick = (
    event?: MouseEvent<HTMLButtonElement>,
    ID?: string,
    index?: number,
    data?: any
  ) => {
    event && setAnchorEl(event.currentTarget)
    setMenu({ ID, index, data })
    // console.log({ ID, index, obj })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return {
    menu,
    anchorEl,
    setAnchorEl,
    handleClick,
    handleClose,
  }
}
