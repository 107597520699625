import { yupResolver } from '@hookform/resolvers/yup'
import {
  ContentWrapper,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { useGuestChangePasswordMutation } from 'generated/graphql'
import React, { FC, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'

export const ChangePasswordSchema = yup.object().shape({
  CurrentPassword: yup.string().required('Current Password is required'),
  NewPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase character'
    )
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase character'
    )
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(
      /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
      'Password must contain at least one symbol'
    ),
  ConfirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('NewPassword'), null], 'Confirm password does not match'),
})
interface ChangePasswordFormProps {
  CurrentPassword: string
  ShowCurrentPassword: boolean
  NewPassword: string
  ShowNewPassword: boolean

  ConfirmPassword: string
  ShowConfirmPassword: boolean
}
export const ChangePassword: FC = () => {
  const history = useHistory()
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)

  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ChangePasswordFormProps>({
    defaultValues: {},
    mode: 'onBlur',
    resolver: yupResolver(ChangePasswordSchema),
  })
  const [GuestChangePassword] = useGuestChangePasswordMutation({
    variables: {
      currentPassword: watch('CurrentPassword'),
      newPassword: watch('NewPassword'),
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.GuestChangePassword) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Change Password Succesfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.goBack()
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Change Password Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
  })

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Change Password',
          props: {
            onClick: () => history.goBack(),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ContentWrapper>
        <StyledCardContent>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue(
                            'ShowCurrentPassword',
                            !getValues('ShowCurrentPassword')
                          )
                        }
                      >
                        {watch('ShowCurrentPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.CurrentPassword?.message}
                error={errors?.CurrentPassword ? true : false}
                autoComplete="off"
                fullWidth
                label="Current Password"
                type={watch('ShowCurrentPassword') ? 'text' : 'password'}
              />
            )}
            name="CurrentPassword"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue(
                            'ShowNewPassword',
                            !getValues('ShowNewPassword')
                          )
                        }
                      >
                        {watch('ShowNewPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.NewPassword?.message}
                error={errors?.NewPassword ? true : false}
                autoComplete="off"
                fullWidth
                label="New Password"
                type={watch('ShowNewPassword') ? 'text' : 'password'}
              />
            )}
            name="NewPassword"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue(
                            'ShowConfirmPassword',
                            !getValues('ShowConfirmPassword')
                          )
                        }
                      >
                        {watch('ShowConfirmPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.ConfirmPassword?.message}
                error={errors?.ConfirmPassword ? true : false}
                autoComplete="off"
                fullWidth
                label="Repeat New Password"
                type={watch('ShowConfirmPassword') ? 'text' : 'password'}
              />
            )}
            name="ConfirmPassword"
            control={control}
          />
        </StyledCardContent>
      </ContentWrapper>
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () => GuestChangePassword(),
              },
              name: 'Save',
            },
          ],
        }}
      />
    </>
  )
}
