import { Spinner, CopyRight } from '@ifca-ui/neumorphism'

import { GuestLayout } from 'containers/App/GuestLayout'
import PublicRoutes from 'containers/Module/Auth/Routes'
import React, { FC, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import { PrivateRoute } from './PrivateRoute'
import RootRoutes from './RootRoutes'

import HotelX from 'assets/images/app-logo-b.svg'
/**
 * @param public & private routes
 *
 */
type RouteProps = {}
export const Routes: FC<RouteProps> = () => {
  return (
    <Switch>
      {PublicRoutes?.map((el: any, index: number) => (
        <Route key={index} {...el.props}>
          <Suspense fallback={<Spinner />}>
            {el.component}
            <CopyRight title={<img src={HotelX} alt="logo" width={50} />} />
          </Suspense>
        </Route>
      ))}

      {RootRoutes?.map((el: any, index: number) => (
        <PrivateRoute key={index} {...el.props}>
          <GuestLayout>
            <Suspense fallback={<Spinner />}>{el.component}</Suspense>
          </GuestLayout>
        </PrivateRoute>
      ))}
    </Switch>
  )
}
