import { StyledMenu } from '@ifca-ui/neumorphism'
import { MenuItem, SvgIcon } from '@material-ui/core'
import { ReactComponent as AboutUsIcon } from 'assets/svg/account/about-us.svg'
import { ReactComponent as ChangePwIcon } from 'assets/svg/account/change-pw.svg'
import { ReactComponent as LogoutIcon } from 'assets/svg/account/logout.svg'
import { ReactComponent as MyProfileIcon } from 'assets/svg/account/my-profile.svg'
import { useHistory } from 'react-router'
import React from 'react'
import { PUBLIC_PATH } from '../Auth/Routes'
import { ACCOUNT_PATH } from './Routes'
export const AccountMenuOption = ({ anchorEl, handleClose }) => {
  const history = useHistory()
  return (
    <StyledMenu
      id="customized-menu"
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => history.push(ACCOUNT_PATH.PROFILE)}>
        <SvgIcon component={MyProfileIcon} viewBox="0 0 30 30" />
        My Profile
      </MenuItem>
      <MenuItem onClick={() => history.push(ACCOUNT_PATH.ABOUT_US)}>
        {' '}
        <SvgIcon component={AboutUsIcon} viewBox="0 0 30 30" />
        About Us
      </MenuItem>
      <MenuItem onClick={() => history.push(ACCOUNT_PATH.CHANGE_PASSWORD)}>
        {' '}
        <SvgIcon component={ChangePwIcon} viewBox="0 0 30 30" />
        Change Password
      </MenuItem>
      {/* <MenuItem onClick={() => history.push(ACCOUNT_PATH.OCR)}>
        {' '}
        <SvgIcon component={ChangePwIcon} viewBox="0 0 30 30" />
        OCR Test
      </MenuItem> */}
      <MenuItem onClick={() => history.push(PUBLIC_PATH.LOGOUT)}>
        {' '}
        <SvgIcon component={LogoutIcon} viewBox="0 0 30 30" />
        Logout
      </MenuItem>
    </StyledMenu>
  )
}
