import { Nationality, Country, State } from "generated/graphql"

export const NationalityEnum: any = Object.values(Nationality).map(i => {
    return i.replace(/_/g, ' ')
  })
  export const CountryEnum: any = Object.values(Country).map(i => {
    return i.replace(/_/g, ' ')
  })
  export const StateEnum: any = Object.values(State).map(i => {
    return i.replace(/_/g, ' ')
  })