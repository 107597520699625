import { ContentWrapper, Spinner } from '@ifca-ui/neumorphism'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined'
import DefaultImg from 'assets/images/default-img.png'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { useHotelDetailsQuery } from 'generated/graphql'
import React, { FC, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { SERVICE_PATH } from '../Routes'
export const Directories: FC = () => {
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    loading,
    data: { HotelDetails } = { HotelDetails: null },
  } = useHotelDetailsQuery({
    variables: {
      // RegistrationID: RegistrationDetails?.ID,
      HotelID: localState?.ID,
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Directories',
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading && <Spinner />}
      <ContentWrapper>
        <GridList cellHeight={180} spacing={15} className="core-image-list">
          {HotelDetails?.Facility &&
            HotelDetails?.Facility?.map((item, i) => (
              <GridListTile
                key={i}
                onClick={() =>
                  history.push(SERVICE_PATH.DIRECTORIES_DETAIL, {
                    Detail: item,
                    ...HotelDetails,
                  })
                }
              >
                <img
                  src={
                    (item.FacilityGallery &&
                      item.FacilityGallery[0]?.Gallery?.ImageURL) ||
                    DefaultImg
                  }
                  alt={item.Title}
                />
                <GridListTileBar
                  title={item.Title}
                  subtitle={<span>Level: {item.Level}</span>}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${item.Title}`}
                      style={{ marginRight: '12px' }}
                    >
                      <KeyboardArrowRightOutlinedIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
        </GridList>
      </ContentWrapper>
    </>
  )
}
