import React, { lazy } from 'react'
import {} from './Hotel'
const Booking = lazy(() =>
  import('./Booking').then(module => ({
    default: module.Booking,
  }))
)
const HotelList = lazy(() =>
  import('./Hotel').then(module => ({
    default: module.HotelList,
  }))
)
const HotelDetail = lazy(() =>
  import('./Hotel').then(module => ({
    default: module.HotelDetail,
  }))
)
const RoomTypeDetail = lazy(() =>
  import('./Hotel').then(module => ({
    default: module.RoomTypeDetail,
  }))
)
const BookingSummary = lazy(() =>
  import('./Hotel').then(module => ({
    default: module.BookingSummary,
  }))
)
const RatingReview = lazy(() =>
  import('./Hotel').then(module => ({
    default: module.RatingReview,
  }))
)
const BookingDetail = lazy(() =>
  import('./BookingDetail/BookingDetail').then(module => ({
    default: module.BookingDetail,
  }))
)
const ERegistration = lazy(() =>
  import('./CheckIn/ERegistration').then(module => ({
    default: module.ERegistration,
  }))
)
const CheckInSummary = lazy(() =>
  import('./CheckIn/CheckInSummary').then(module => ({
    default: module.CheckInSummary,
  }))
)
const InvoiceDocument = lazy(() =>
  import('../Services/InvoiceDocument').then(module => ({
    default: module.InvoiceDocument,
  }))
)
export const BOOKING_PATH = {
  ROOT: '/',
  BOOKING_LIST: '/my-booking',
  BOOKING_LIST_FROM_SERVICE: '/booking',
  BOOKING_LIST_PAYMENT: '/booking/redirect/:status',
  BOOKING_DETAIL: '/booking/detail',
  BOOKING_INVOICE: '/booking/detail/invoice',
  BOOKING_CHECKIN: '/booking/detail/checkin',
  BOOKING_CHECKIN_SUMMARY: '/booking/detail/checkin/summary',
  HOTEL_LIST: '/booking/hotel',
  HOTEL_DETAIL: '/booking/hotel/detail',
  HOTEL_ROOMTYPE_DETAIL: '/booking/hotel/detail/roomtype-detail',
  HOTEL_RATING_REVIEW: '/booking/hotel/detail/rating-review',
  BOOKING_SUMMARY: '/booking/hotel/detail/summary',
}
const BookingRoutes = [
  {
    props: { exact: true, path: BOOKING_PATH.ROOT },
    component: <Booking />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_LIST },
    component: <Booking />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_LIST_FROM_SERVICE },
    component: <Booking />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_LIST_PAYMENT },
    component: <Booking />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.HOTEL_LIST },
    component: <HotelList />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.HOTEL_DETAIL },
    component: <HotelDetail />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.HOTEL_ROOMTYPE_DETAIL },
    component: <RoomTypeDetail />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.HOTEL_RATING_REVIEW },
    component: <RatingReview />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_DETAIL },
    component: <BookingDetail />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_CHECKIN },
    component: <ERegistration />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_CHECKIN_SUMMARY },
    component: <CheckInSummary />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_SUMMARY },
    component: <BookingSummary />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_INVOICE },
    component: <InvoiceDocument Mode={'Booking'} />,
  },
]

export default BookingRoutes
