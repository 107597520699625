import { ContentWrapper, Spinner } from '@ifca-ui/neumorphism'
import { Fab, List, ListItem, ListItemText, Modal } from '@material-ui/core'
import CardPromo from 'assets/svg/service/promo-card-curves.svg'
import ScanIcon from 'assets/svg/service/scan-promo.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import {
  usePackageRedemptionInsertMutation,
  usePackageRedemptionListingQuery,
} from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import QrReader from 'react-qr-reader'
import { useHistory, useLocation } from 'react-router'
import { SERVICE_PATH } from './Routes'

interface Props {
  QRScannerModal: boolean
  QrScanner?: null | any
}
export const PackageRedemption: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    refetch,
    loading,
    data: { PackageRedemptionListing } = { PackageRedemptionListing: [] },
  } = usePackageRedemptionListingQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
      RegistrationID: localState?.Registration?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  const [
    PackageRedemptionInsert,
    { loading: insertLoading },
  ] = usePackageRedemptionInsertMutation()

  const initialState: Props = {
    QRScannerModal: false,
    QrScanner: null,
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'HotelDialog':
        return { ...state, Dialog: { Open: true, Mode: 'View' } }

      case 'CloseDialog':
        return { ...state, Dialog: { Open: false, Mode: '' } }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Package Redemption',
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (state.QrScanner) {
      dispatch({ type: 'QRScannerModal', payload: false })
      PackageRedemptionInsert({
        variables: {
          PackageRedemptionInput: {
            HotelID: localState?.Hotel?.ID,
            DepartmentID: state.QrScanner?.split('/')[1],
            IncidentalChargeID: state.QrScanner?.split('/')[2],
            BookingID: localState?.Registration?.Booking?.ID,
            RegistrationID: localState?.Registration?.ID,
            RoomNo: localState?.Registration?.Room?.RoomNo,
            TrxDate: new Date(),
          },
        },
      }).then(i => {
        if (i.data.PackageRedemptionInsert) {
          refetch()
          dispatch({ type: 'QRScannerModal', payload: false })
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Redeemed Successfully',
            },
          })
        } else {
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Redeem Fail',
            },
          })
        }
      })
      refetch()
    }
  }, [state.QrScanner])

  const handleScan = data => {
    if (data) {
      dispatch({
        type: 'QrScanner',
        payload: data,
      })
    }
  }
  const handleError = err => {
    console.log(err)
  }
  const QRReader = (
    <Modal
      open={state.QRScannerModal}
      onClose={() => dispatch({ type: 'QRScannerModal', payload: false })}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <QrReader
        delay={300}
        onError={err => handleError(err)}
        onScan={data => handleScan(data)}
        className="qr-reader"
        style={{ width: '100%' }}
      />
    </Modal>
  )
  console.log(PackageRedemptionListing?.every(el => el.Redeemed > 0))
  return (
    <>
      {(loading || insertLoading) && <Spinner />}{' '}
      <ContentWrapper>
        <List className="core-list">
          {PackageRedemptionListing?.length === 0 && (
            <div
              className=" xsTitle m-t-10 m-b-10"
              style={{ textAlign: 'center' }}
            >
              No Record Found.
            </div>
          )}
          {PackageRedemptionListing?.map((el, i) => (
            <ListItem key={i}>
              <ListItemText
                primary={
                  <>
                    <span className="xsTitle fw-500 flex-space">
                      {el.Description}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc color-text-1 flex-space">
                      Pax: {el.Total} | Redeemed: {el.Redeemed}
                    </span>
                    <img
                      src={CardPromo}
                      alt=""
                      style={{
                        top: 0,
                        right: -10,
                        height: '68px',
                        position: 'absolute',
                      }}
                    />
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
      {PackageRedemptionListing?.every(el => el.Redeemed > 0) ? null : (
        <Fab
          color="primary"
          aria-label="add"
          className="fab"
          onClick={() => dispatch({ type: 'QRScannerModal', payload: true })}
        >
          <img src={ScanIcon} alt="remove" />
        </Fab>
      )}
      {QRReader}
    </>
  )
}
