import { ContentWrapper, StyledCardContent } from '@ifca-ui/neumorphism'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import React, { FC, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SERVICE_PATH } from './Routes'

// install Swiper modules
SwiperCore.use([Pagination])
export const AdsDetail: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: localState?.Title,
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ContentWrapper>
        <div className="room-info-wrapper">
          <Swiper pagination={true} className="promo-swiper">
            <SwiperSlide>
              <img
                src={localState?.Gallery?.ImageURL}
                alt={localState?.Gallery?.ImageURL}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className=" m-b-14 m-t-20 ">
          <div className="mdDesc text-noflow">{localState?.Description}</div>
        </div>
        <StyledCardContent>
          <div
            className="desc text-noflow html-template"
            dangerouslySetInnerHTML={{ __html: localState?.html }}
          />
        </StyledCardContent>
      </ContentWrapper>
    </>
  )
}
