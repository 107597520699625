/* eslint-disable react-hooks/exhaustive-deps */
import { ContentWrapper } from '@ifca-ui/neumorphism'
import { Grid, IconButton, SwipeableDrawer } from '@material-ui/core'
import DefaultImg from 'assets/images/default-img.png'
import AdultsIcon from 'assets/svg/booking/adult.svg'
import MoreIcon from 'assets/svg/more.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BOOKING_PATH } from '../Routes'

// install Swiper modules
SwiperCore.use([Pagination])
interface Props {
  Drawer: boolean
  DrawerMode?: string
}
export const RoomTypeDetail: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const { RootDispatch } = useContext<AppContextProps>(AppContext)
  console.log(localState)
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        header: {
          headerMode: 'secondary',
        },
        left: {
          icon: 'leftArrow',
          title: localState?.RoomTypeSelected?.Description,
          props: {
            onClick: () =>
              history.push(BOOKING_PATH.HOTEL_DETAIL, { ...localState }),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialState: Props = {
    Drawer: false,
    DrawerMode: '',
  }

  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'ShareDrawer':
        return { ...state, Drawer: true, DrawerMode: 'Share' }
      case 'AmenitiesDrawer':
        return { ...state, Drawer: true, DrawerMode: 'Amenities' }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const Amenities = localState?.RoomTypeSelected?.RoomTypeAmenities.filter(
    x => x.IsSelected
  )
  const getUniqueAmenities = (arr, key: string) => {
    return [
      ...(new Map(arr?.map((item: any) => [item[key], item]))?.values() || []),
    ]
  }

  const AmenitiesList = (
    <Grid container justify="flex-start" className="amenities-wrapper">
      {Amenities?.slice(0, 4)?.map((x: any, i) => (
        <Grid item xs={true} key={i}>
          <IconButton size="small">
            <img
              src={require(`../../../../` + x?.Gallery?.ImageURL).default}
              alt={x.Name}
            />
          </IconButton>
          <div className="desc text-overflow label">{x.Name}</div>
        </Grid>
      ))}
      {Amenities?.length > 0 ? (
        <Grid item xs={true}>
          <IconButton
            size="small"
            onClick={() => dispatch({ type: 'AmenitiesDrawer', payload: {} })}
          >
            <img src={MoreIcon} alt="more" />
          </IconButton>
          <div className="mdDesc">More</div>
        </Grid>
      ) : null}
    </Grid>
  )
  const ShareDrawer = (
    <SwipeableDrawer
      className="drawer-wrapper"
      anchor={'bottom'}
      open={state.Drawer}
      onClose={() => dispatch({ type: 'Drawer', payload: false })}
      onOpen={() => dispatch({ type: 'Drawer', payload: true })}
    >
      <div className="color-divider" />

      <Grid
        container
        justify="flex-start"
        className="amenities-wrapper"
        style={{
          height: 350,
        }}
      >
        {(getUniqueAmenities(Amenities, 'Name') || [])?.map((x: any, i) => (
          <Grid item xs={4} key={i} className="m-b-4">
            <IconButton size="small">
              <img
                src={require(`../../../../` + x?.Gallery?.ImageURL).default}
                alt="share"
              />
            </IconButton>
            <div
              className="desc text-overflow "
              style={{
                whiteSpace: 'break-spaces',
              }}
            >
              {x.Name}
            </div>
          </Grid>
        ))}
      </Grid>
    </SwipeableDrawer>
  )
  return (
    <>
      <Swiper pagination={true} className="hotel-swiper">
        {localState?.RoomTypeSelected?.RoomTypeGallery?.map(
          (v: any, i: number) => (
            <SwiperSlide key={i}>
              <img src={v.Gallery?.ImageURL || DefaultImg} alt="" />
            </SwiperSlide>
          )
        )}
      </Swiper>
      <ContentWrapper imgHeader footer>
        <div className="flex-width hotel-detail-wrapper">
          <div className="flex-space">
            <div className="title m-b-4 text-noflow">
              {/* {HotelPricesByDateRange?.HotelName} */}
              {localState?.RoomTypeSelected?.Description}
            </div>
            <div className="mdDesc text-noflow m-b-4 icon-text">
              <img
                src={AdultsIcon}
                alt={`${localState?.RoomTypeSelected?.MaxGuest}`}
                width={20}
                height={20}
                style={{
                  paddingRight: '4px',
                }}
              />{' '}
              {localState?.RoomTypeSelected?.MaxGuest} Adults
            </div>
          </div>
        </div>
        <div className="b-b m-t-12 m-b-12" />

        <div className="title m-b-4">Amenities</div>
        <div className="title m-b-12">{AmenitiesList}</div>
        <div className="b-b m-t-12 m-b-12" />

        <div className="m-b-4 title m-b-14">Hotel Policies </div>
        <div>
          <span
            className="desc text-noflow"
            dangerouslySetInnerHTML={{
              __html: localState?.Hotel?.OnlineTnC,
            }}
          />
        </div>
        {/* <List className="core-list ">
          {state.RoomAvailable?.map((el, index: number) => (
            <Badge
              key={index}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              color="secondary"
              badgeContent={el.TotalPackages?.reduce((acc, curr) => {
                return acc + curr.TotalNumOfRoom
              }, 0)}
              className="hotel"
            >
              <ListItem
                key={index}
                className="hotel"
                // onClick={() =>
                //   history.push('/booking/hotel/detail', {
                //     HotelDetail: i,
                //     ...localState,
                //   })
                // }
              >
                <ListItemAvatar>
                  <Avatar
                    onClick={() =>
                      history.push(BOOKING_PATH.HOTEL_ROOMTYPE_DETAIL, {
                        ...localState,
                        RoomTypeSelected: el,
                      })
                    }
                    variant="rounded"
                    alt={el?.Code}
                    src={
                      (el.RoomTypeGallery &&
                        el.RoomTypeGallery[0]?.Gallery?.ImageURL) ||
                      DefaultImg
                    }
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle fw-500 flex-space">
                        {el.Description}
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="mdDesc  icon icon-text">
                        <img
                          src={AdultsIcon}
                          alt={`${el.MaxGuest}`}
                          width={20}
                          height={20}
                        />{' '}
                        {el.MaxGuest} Adults
                      </span>
                      {el.RoomTypeAmenities?.find(
                        x => x.Name === 'Non-smoking' && x.IsSelected
                      ) && (
                        <span className="mdDesc flex-width icon icon-text">
                          <img
                            src={NoSmokingIcon}
                            alt={`${el.MaxGuest}`}
                            width={20}
                            height={20}
                          />{' '}
                          Non-Smoking
                        </span>
                      )}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={event => {
                      dispatch({
                        type: 'AnchorEl',
                        payload: event.currentTarget,
                      })
                      dispatch({
                        type: 'IndexSelected',
                        payload: index,
                      })
                    }}
                    className="hotel"
                    aria-describedby={ID}
                  >
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Badge>
          ))}
        </List> */}
      </ContentWrapper>
      {ShareDrawer}
    </>
  )
}
